import React from 'react'

function cancel() {
  return (
    <div className='p-5'>
        <center><h3>Cancellation & Refund Policy</h3></center>
        <hr />
        <p className='px-5'>
            <h5>1. Window for Cancellations:</h5>
            <ul>
                <li> Orders may be canceled by customers within 24 hours of placement.</li>
                <li>
                Refunds might not be available if the order has already been shipped or processed.
                </li>
            </ul>
            <h5>2. Cancellation Procedure:</h5>
            <ul>
                <li> To cancel an order, consumers can use the cancellation link in their account or email our customer support team at business@hashbug.in.</li>
                <li>
                Provide the order number and the explanation for the cancellation.  
                </li>
            </ul>
            <h5>
            3. Refund Procedure:
            </h5>
            <ul>
                <li>
                Within 30 days of the order's cancellation, the original payment method will receive a complete refund.
                </li>
                <li>
                Customers must instead initiate a return if the order has already arrived.
                </li>
            </ul>
            <h5>
            4. Changes to Orders:
            </h5>
            <ul>
                <li>
                Customers may request changes to their order like shipping address and item quantity before it has been processed. Please contact us as soon as possible.

                </li>
            </ul>
            <h5>
          
5. Exceptions:

            </h5>
            <ul>
                <li>
               
We maintain the right to reject cancellation requests under certain conditions, such as fraudulent orders.

                </li>
            </ul>
            <h5>
          
            6. Customer Support:

            </h5>
            <ul>
                <li>
               
                Contact our support team at business@hashbug.in/+91 8127133951 if you have any questions or need any help with cancellations.

                </li>
            </ul>
        </p>
      
    </div>
  )
}

export default cancel
