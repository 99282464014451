import React from 'react';

function PaymentSuccess() {
  return (
    <div className="container my-5" style={{paddingTop:'100px', paddingBottom:'100px'}}>
      <h2 className="text-center text-success mb-3">Thank you for your purchase.</h2>
      <p className="text-center"> We will keep you updated on your payment status and order status via email.</p>
    </div>
  );
}

export default PaymentSuccess;
