import L_Handle_set from "../img/bot1.avif";
import Fat_Grip_For_Cupping from "../img/bot_plast.jpg";
import Cone_Grip from "../img/bot_printed.jpg";
import Wrist_Wrench from "../img/Wrist_Wrench.png";
import Cupping_Handle from "../img/Cupping_Handle.png";
import Multi_Spinner from "../img/Multi_Spinner.png";
import TRINGA_ECCENTRIC_SET from "../img/TRINGA_ECCENTRIC_SET.png";
import Palm_Cupping_L_Handle from "../img/Palm_Cupping_L_Handle.png";
import Loading_Pin from "../img/Loading_Pin.png";
import Hand_Winder from "../img/Hand_Winder.png";
import Tiranga_Eccentric_Grip from "../img/Tiranga_Eccentric_Grip.png";
import Elliptical_Wrist_Ball from "../img/Elliptical_Wrist_Ball.png";
import Premium_Table_Strap from "../img/Premium_Table_Strap.png";
import Eccentric_Grip from "../img/Eccentric_Grip.png";
import Professional_Wrist_Ball from "../img/Professional_Wrist_Ball.png";
import Devon_Larrat_Cupping_Handles from "../img/Devon_Larrat_Cupping_Handles.png";
import Pronation_Ring_with_Hook from "../img/Pronation_Ring_with_Hook.png";
import Arm_Wrestling_Training_Strap from "../img/Arm_Wrestling_Training_Strap.png";
import Stainless_Steel_15mm_C_Handle from "../img/Stainless_Steel_15mm_C_Handle.png";
import Arm_Wrestling_Handles_Set from "../img/Arm_Wrestling_Handles_Set.png";
import Claws_Arm_Wrestling_Table from "../img/Claws_Arm_Wrestling_Table.png";
import Arm_Wrestling_Table_Pulley from "../img/Arm_Wrestling_Table_Pulley.png";
import Professional_Training_Strap from "../img/Professional_Training_Strap.png";
import Claws_Cotton_Denis_T_Shirt_Black from "../img/Claws_Cotton_Denis_T-Shirt_Black.png";
import product1 from "../img/product-1.JPG";
import cups_1 from "../img/cups_1.jpg";
import cups_2 from "../img/cups_2.jpg";
import cups_3 from "../img/cups_3.jpg";
import cups_4 from "../img/cups_4.jpg";
import cups_5 from "../img/cups_5.jpg";
import bags_1 from "../img/bags_1.jpg";
import bags_2 from "../img/bags_2.jpg";
import bags_3 from "../img/bags_3.webp";
import bags_4 from "../img/marvel.jpg";
import w1 from "../img/women/Artboard 1.jpg"
import w2 from "../img/women/Artboard 2.jpg"
import w3 from "../img/women/Artboard 3.jpg"
import w4 from "../img/women/Artboard 4.jpg"
import w5 from "../img/women/Artboard 5.jpg"
import w6 from "../img/women/Artboard 6.jpg"
import w7 from "../img/women/Artboard 7.jpg"
import w8 from "../img/women/Artboard 8.jpg"
import w9 from "../img/women/Artboard 9.jpg"
import w10 from "../img/women/Artboard 10.jpg"
import w11 from "../img/women/Artboard 11.jpg"
import w12 from "../img/women/Artboard 12.jpg"
import w13 from "../img/women/Artboard 13.jpg"
import w14 from "../img/women/Artboard 14.jpg"
import w15 from "../img/women/Artboard 15.jpg"
import a1 from "../img/products/product04.jpg"
import a2 from "../img/products/product05.jpg"
import a3 from "../img/products/product06.jpg"
import a4 from "../img/products/product08.jpg"
import a5 from "../img/products/product09.jpg"
import a6 from "../img/products/product10.jpg"
import a7 from "../img/products/product11.jpg"
import a8 from "../img/products/product12.jpg"
import a9 from "../img/products/product13.jpg"
import a10 from "../img/products/product14.jpg"
import a11 from "../img/products/product15.jpg"
import a12 from "../img/products/product16.jpg"
import a13 from "../img/products/product17.jpg"
import a14 from "../img/products/product18.jpg"
import a15 from "../img/products/product19.jpg"
import a16 from "../img/products/product20.jpg"
import a17 from "../img/products/product21.jpg"
import a18 from "../img/products/product22.jpg"
import a19 from "../img/products/product23.jpg"
import a20 from "../img/products/product24.jpg"
import a21 from "../img/products/product25.jpg"
import a22 from "../img/products/product26.jpg"
import a23 from "../img/products/product27.jpg"
import a24 from "../img/products/product28.jpg"
import a25 from "../img/products/product29.jpg"
import a26 from "../img/products/product30.jpg"
import a27 from "../img/products/product31.jpg"
import a28 from "../img/products/product32.jpg"
import a29 from "../img/products/product33.jpg"
import a30 from "../img/products/product34.jpg"
import a31 from "../img/products/product35.jpg"
import a32 from "../img/products/product36.jpg"
import a33 from "../img/products/product37.jpg"
import a34 from "../img/products/product38.jpg"
import a35 from "../img/products/product39.jpg"
import a36 from "../img/products/product40.jpg"
import a37 from "../img/products/product41.jpg"
import a38 from "../img/products/product44.jpg"





















const Cardsdata = [
   
    {
        id: 35,
        rname: "Women's outfits ",
        imgdata: w1,
        somedata: "Explore high-quality products crafted to meet your needs, offering a perfect blend of style, durability, and value.",

        price: 499,
        rating: "3.8",
        qnty:0,
        category:"dresses",
    },
   
    {
        "id": 36,
        "rname": "Women's outfits",
        "imgdata": w2,
        "somedata": "Chic and versatile, these dresses are perfect for both casual outings and special occasions.",
        "price": 4700,
        "rating": "4.2",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 37,
        "rname": "Women's outfits",
        "imgdata": w3,
        "somedata": "Timeless fashion pieces crafted with premium fabrics for a luxurious feel.",
        "price": 2900,
        "rating": "4.5",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 38,
        "rname": "Women's outfits",
        "imgdata": w4,
        "somedata": "Bold designs that empower women to express their unique style with confidence.",
        "price": 6800,
        "rating": "4.0",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 39,
        "rname": "Women's outfits",
        "imgdata": w5,
        "somedata": "Comfort meets fashion in these stylish dresses, perfect for every occasion.",
        "price": 5300,
        "rating": "3.9",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 40,
        "rname": "Women's outfits",
        "imgdata": w6,
        "somedata": "Elegant and modern, these dresses offer a sophisticated look for any wardrobe.",
        "price": 6200,
        "rating": "4.3",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 41,
        "rname": "Women's outfits",
        "imgdata": w7,
        "somedata": "Designed for the fashion-forward woman, these pieces are both timeless and trendy.",
        "price": 4000,
        "rating": "4.1",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 42,
        "rname": "Women's outfits",
        "imgdata": w8,
        "somedata": "Sophisticated, comfortable, and perfect for a wide range of occasions.",
        "price": 5800,
        "rating": "3.7",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 43,
        "rname": "Women's outfits",
        "imgdata": w9,
        "somedata": "Flattering silhouettes that effortlessly combine comfort with contemporary style.",
        "price": 4500,
        "rating": "4.0",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 44,
        "rname": "Women's outfits",
        "imgdata": w10,
        "somedata": "Effortlessly chic dresses designed to make a statement with minimal effort.",
        "price": 3100,
        "rating": "3.9",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 45,
        "rname": "Women's outfits",
        "imgdata": w11,
        "somedata": "Beautifully crafted with attention to detail, offering both elegance and durability.",
        "price": 6200,
        "rating": "4.4",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 46,
        "rname": "Women's outfits",
        "imgdata": w12,
        "somedata": "Elevate your wardrobe with these versatile and stylish women's dresses.",
        "price": 5300,
        "rating": "4.2",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 47,
        "rname": "Women's outfits",
        "imgdata": w13,
        "somedata": "Luxury dresses that embody both contemporary fashion and timeless elegance.",
        "price": 6500,
        "rating": "4.1",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 48,
        "rname": "Women's outfits",
        "imgdata": w14,
        "somedata": "High-fashion dresses designed for women who love to make a bold statement.",
        "price": 7000,
        "rating": "4.3",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 49,
        "rname": "Women's outfits",
        "imgdata": w15,
        "somedata": "Modern, comfortable, and effortlessly stylish dresses for every woman.",
        "price": 2900,
        "rating": "3.8",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 50,
        "rname": "Women's accessory",
        "imgdata": a1,
        "somedata": "Stylish and elegant accessories that add the perfect finishing touch to any outfit.",
        "price": 3400,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 51,
        "rname": "Women's accessory",
        "imgdata": a2,
        "somedata": "Chic and modern accessories designed to complement any style with ease.",
        "price": 6200,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 52,
        "rname": "Women's accessory",
        "imgdata": a3,
        "somedata": "Luxury accessories crafted with attention to detail, offering both elegance and durability.",
        "price": 2700,
        "rating": "3.9",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 53,
        "rname": "Women's accessory",
        "imgdata": a4,
        "somedata": "Timeless pieces that bring a touch of sophistication to any outfit.",
        "price": 5300,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 54,
        "rname": "Women's accessory",
        "imgdata": a5,
        "somedata": "Beautiful accessories designed to add a unique flair to your wardrobe.",
        "price": 4800,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 55,
        "rname": "Women's accessory",
        "imgdata": a6,
        "somedata": "Sophisticated, modern, and perfect for any occasion.",
        "price": 6700,
        "rating": "4.5",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 56,
        "rname": "Women's accessory",
        "imgdata": a7,
        "somedata": "Elegant accessories that perfectly balance fashion and function.",
        "price": 4500,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 57,
        "rname": "Women's accessory",
        "imgdata": a8,
        "somedata": "Delicate yet durable accessories that enhance your everyday style.",
        "price": 5200,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 58,
        "rname": "Women's accessory",
        "imgdata": a9,
        "somedata": "Bold and beautiful accessories that make a statement wherever you go.",
        "price": 3900,
        "rating": "3.8",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 59,
        "rname": "Women's accessory",
        "imgdata": a10,
        "somedata": "Handcrafted accessories designed to inspire and empower your personal style.",
        "price": 6000,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 60,
        "rname": "Women's accessory",
        "imgdata": a11,
        "somedata": "Charming and versatile accessories that complete any outfit.",
        "price": 3200,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 61,
        "rname": "Women's accessory",
        "imgdata": a12,
        "somedata": "Unique accessories crafted with care, perfect for enhancing any look.",
        "price": 5100,
        "rating": "3.9",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 62,
        "rname": "Women's accessory",
        "imgdata": a13,
        "somedata": "Effortlessly stylish accessories for the modern woman.",
        "price": 2900,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 63,
        "rname": "Women's accessory",
        "imgdata": a14,
        "somedata": "Fashion-forward accessories that add a finishing touch to any outfit.",
        "price": 6800,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 64,
        "rname": "Women's accessory",
        "imgdata": a15,
        "somedata": "Stylish accessories designed to elevate your everyday look.",
        "price": 3600,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 65,
        "rname": "Women's accessory",
        "imgdata": a16,
        "somedata": "High-quality accessories crafted for durability and timeless style.",
        "price": 5400,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 66,
        "rname": "Women's accessory",
        "imgdata": a17,
        "somedata": "Modern accessories that seamlessly blend elegance and practicality.",
        "price": 4600,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 67,
        "rname": "Women's accessory",
        "imgdata": a18,
        "somedata": "Accessories designed to enhance and complement every style.",
        "price": 5300,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 68,
        "rname": "Women's accessory",
        "imgdata": a19,
        "somedata": "Versatile accessories crafted to add the perfect finishing touch.",
        "price": 3700,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 69,
        "rname": "Women's accessory",
        "imgdata": a20,
        "somedata": "Beautifully crafted accessories designed to complement any wardrobe.",
        "price": 6000,
        "rating": "4.4",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 70,
        "rname": "Women's accessory",
        "imgdata": a21,
        "somedata": "Fashionable accessories that effortlessly blend style and comfort.",
        "price": 4100,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 71,
        "rname": "Women's accessory",
        "imgdata": a22,
        "somedata": "Timeless accessories for the modern woman seeking effortless elegance.",
        "price": 6900,
        "rating": "4.5",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 72,
        "rname": "Women's accessory",
        "imgdata": a23,
        "somedata": "Accessories that balance everyday functionality with fashion-forward style.",
        "price": 4600,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 73,
        "rname": "Women's accessory",
        "imgdata": a24,
        "somedata": "Handcrafted accessories made with care for the modern woman.",
        "price": 5400,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 74,
        "rname": "Women's accessory",
        "imgdata": a25,
        "somedata": "Bold and eye-catching accessories designed for women who love to stand out.",
        "price": 3200,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 75,
        "rname": "Women's accessory",
        "imgdata": a26,
        "somedata": "Elegant and versatile accessories that suit any occasion.",
        "price": 2500,
        "rating": "3.9",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 76,
        "rname": "Women's accessory",
        "imgdata": a27,
        "somedata": "Beautifully designed accessories that add charm and sophistication to any outfit.",
        "price": 5800,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 77,
        "rname": "Women's accessory",
        "imgdata": a28,
        "somedata": "Chic and fashionable accessories perfect for everyday wear or special occasions.",
        "price": 4300,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 78,
        "rname": "Women's accessory",
        "imgdata": a29,
        "somedata": "High-quality accessories designed to last and make a statement.",
        "price": 6300,
        "rating": "4.4",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 79,
        "rname": "Women's accessory",
        "imgdata": a30,
        "somedata": "Affordable luxury with these stunning, carefully crafted accessories.",
        "price": 3400,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 80,
        "rname": "Women's accessory",
        "imgdata": a31,
        "somedata": "Everyday accessories that effortlessly blend style and comfort.",
        "price": 4100,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 81,
        "rname": "Women's accessory",
        "imgdata": a32,
        "somedata": "Timeless, handcrafted accessories designed for the modern woman.",
        "price": 6600,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 82,
        "rname": "Women's accessory",
        "imgdata": a33,
        "somedata": "Classic accessories that never go out of style.",
        "price": 4800,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 83,
        "rname": "Women's accessory",
        "imgdata": a34,
        "somedata": "Delicate accessories that enhance any outfit with effortless style.",
        "price": 2900,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 84,
        "rname": "Women's accessory",
        "imgdata": a35,
        "somedata": "Elegant and stylish accessories crafted with care and attention to detail.",
        "price": 5100,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 85,
        "rname": "Women's accessory",
        "imgdata": a36,
        "somedata": "Modern accessories that perfectly complement any look.",
        "price": 6700,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 86,
        "rname": "Women's accessory",
        "imgdata": a37,
        "somedata": "Unique and versatile accessories for the fashion-conscious woman.",
        "price": 4300,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 87,
        "rname": "Women's accessory",
        "imgdata": a38,
        "somedata": "Bold and beautiful accessories that enhance any style with ease.",
        "price": 3700,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    }

];

export default Cardsdata;