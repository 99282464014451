import React from 'react';
import "../Style/footer.css";
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { CDBFooter, CDBFooterLink, CDBBox, CDBBtn, CDBIcon } from 'cdbreact';
import img from "../img/logo.png";

function Footer() {

    const phoneNumber1 = '+919582499831'; // Replace with your WhatsApp number
    const message = 'Hello sir!'; // Replace with your message
  
    // Function to format the WhatsApp URL
    const getWhatsAppLink = () => {
      return `https://wa.me/${phoneNumber1}?text=${encodeURIComponent(message)}`;
    };
  
    // Function to handle button click
    const handleWhatsAppButtonClick = () => {
      window.open(getWhatsAppLink(), '_blank');
    };


    const username = 'clawsarmwrestling.in'; // Replace with your Instagram username

  // Function to get the Instagram URL
  const getInstagramLink = () => {
    return `https://www.instagram.com/${username}`;
  };
  const handleInstagramButtonClick = () => {
    window.open(getInstagramLink(), '_blank');  
  };


  const phoneNumber = '+919999999999'; // Replace with your phone number
  const phoneNumber2 = '+91999999999'; // Replace with your phone number

  // Function to handle button click
  const handlePhoneButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const handle = () => {
    window.location.href = `tel:${phoneNumber2}`;
  };

  return (
    <div className='Footer_container' id='footer' >
      
       
        
       <footer className=" text-white  p-4 text-start" style={{backgroundColor:'#374151' }} >
      <div className="container">
        <div className="row">
          <div className="col-md-5">
          <img
                  style={{ height: "60px", width: "170px", marginLeft:'-18px' }}
                  src={img}
                  alt="Logo"
                  className="img-fuild"
                />

    

            <p>
            We are a top-tier e-commerce platform committed to delivering an unparalleled shopping experience, offering a selection of high-quality products at unbeatable prices. Whether you're searching for essentials or specialty items, we ensure the best value without compromising on quality.
            </p>
          </div>
          <div className="col-md-3 ">
            <h5 >Quick Links</h5>
            <ul className="list-unstyled">
              <li><Link to="/" className="text-white text-start">Home</Link></li>
              <li><Link to="/Product_details" className="text-white">Shop</Link></li>
              <li><Link to="/about-us" className="text-white">About</Link></li>
              <li><Link to="/privacy-policy" className="text-white">Privacy & Policy</Link></li>
              <li><Link to="/shipping-policy" className="text-white">Shipping Policy</Link></li>
              <li><Link to="/cancellation-policy" className="text-white">Cancellation Policy</Link></li>


            </ul>
          </div>
          <div className="col-md-4 pt-2">
            <h5>Contact Us</h5>
            <p>
              Email: business@hashbug.in
              <br />
              Phone: +91 8127133951
              <br />
              Address: 3rd Floor, BPTP Center One, Sector 61, Golf Course Extension
              Road, Gurugram, Haryana   
      
            </p>
            {/* <div>
              <a href="#" className="text-white me-3">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="#" className="text-white me-3">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="text-white">
                <i className="fab fa-instagram"></i>
              </a>
            </div> */}
          </div>
        </div>
        <hr className="bg-light" />
        <p className="mb-0">&copy; 2024 HASHBUG. All Rights Reserved.</p>
      </div>
    </footer>

        

    </div>
  )
}

export default Footer